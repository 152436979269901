<template>
  <div class="wap-box" @click="searchHot=false;">
    <div class="wap-content" ref="content" @scroll="handleScroll">
      <transition
        mode="out-in"
        enter-active-class="fadeInLeft"
        leave-active-class="fadeOutRight"
      >
        <router-view  v-if="isShow" class=" animated sy-transition-box"></router-view>
      </transition>
    </div>
    
    <img src="../assets/images/index/icon-goTop.png" id="default_drag_return" v-show="goTopShow" style="bottom:70px;" @click="goTop()" alt="">

    <van-tabbar v-model="active">
      <van-tabbar-item name="mizun" @click="toGo('/mizun');appReload(); active='mizun';setBottomNav()">
      
      <template #icon="props">
        <img :src="props.active ? mizunIcon.active : mizunIcon.inactive" style="margin:0 auto;width: 20px;" />
      </template>
      <span>密尊</span>
      </van-tabbar-item>
      <van-tabbar-item name="index" @click="toGo('/index');appReload();active='index';setBottomNav()">
      
      <template #icon="props">
        <img :src="props.active ? indexIcon.active : indexIcon.inactive" style="margin:0 auto;width: 20px;"/>
      </template>
      <span>珠宝</span>
      </van-tabbar-item>

      <van-tabbar-item name="lzg" @click="toGo('/lzg');appReload();active='lzg';setBottomNav()">
      
      <template #icon="props">
        <img :src="props.active ? lzgIcon.active : lzgIcon.inactive" style="margin:0 auto;width: 20px;"/>
      </template>
      <span>龙主宫</span>
      </van-tabbar-item>
      <!-- <van-tabbar-item name="user" icon="friends-o" @click="toGo('/user');appReload();active='user'">龙主宫</van-tabbar-item> -->
    </van-tabbar>
    <!-- <div class="sy-share-box">
      <h2 class="sy-share-title">分享此页面</h2>
      <vshare :vshareConfig="vshareConfig"></vshare>
    </div> -->
    
  </div>
</template>
<script> 
import {select2Category,getSidebar} from "../utils/api";

import {mapState,mapMutations} from "vuex";
export default {
  data() {
    return {
      mizunIcon:{
      active: require('@/assets/images/common/mizun2.png'),
      inactive: require('@/assets/images/common/mizun.png'),
      },
      indexIcon:{
      active: require('@/assets/images/common/index2.png'),
      inactive:require('@/assets/images/common/index.png'),
      },
      lzgIcon:{
      active: require('@/assets/images/common/lzg2.png'),
      inactive:require('@/assets/images/common/lzg.png'),
      },
      isShow: true,
      activeNames:[],
      active:'index',
      wrapScrollSY: {},
      navLits:[],
      goTopShow:false,
      hide:false,
      show:true,
      showNav:false,
      showSearch:false,
      searchText:'',
      searchHot:false,
      nowscrollTop:0,
      cpList:[
      ],
      vshareConfig: {
        shareList: [
          'qzone',
          'tsina',
          'douban',
          'weixin',
          // 此处放分享列表（ID）
        ],
        common : {
          //此处放置通用设置
        },
        share : [{
          //此处放置分享按钮设置
          }
        ],
        image : [
          //此处放置图片分享设置
        ],
        selectShare : [
          //此处放置划词分享设置
        ]
      }
      
    };
  },
  provide () {
    return {
      appReload: this.appReload
    }
  },

  watch: {
      '$store.state.bottomNav'(newVal,oldVal){
        //对数据进行操作
        console.log(newVal,oldVal)
        this.active=newVal;
      }
  },
  created(){
    
  },
  mounted() {
     this.$refs.content.addEventListener('scroll', this.handleScroll);
    // window.addEventListener('scroll',this.handleScroll);

    if(this.$store.state.bottomNav){
      this.active=this.$store.state.bottomNav;
    }
    this.select2Category();
  },
  methods: {
    ...mapMutations(["saveBottomNav"]),
    handleScroll(){
      let scrollTop = this.$refs.content.scrollTop; // 滚动条偏移量
      
      if(scrollTop>600){
        this.goTopShow=true;
      }
      if(scrollTop<600){
        this.goTopShow=false;
      }
    },
    goTop(){
      console.log('置顶')
      this.$refs.content.scrollTop = 0;
    },
    setBottomNav(){
      this.saveBottomNav(this.active);
    },
    appReload () {
      // 先隐藏
      this.isShow = false
      
      // $nextTick() 将回调延迟到下次 DOM 更新循环之后执行
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    select2Category(){
      select2Category()
      .then((res) => {
        console.log(res);
        this.cpList=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getSidebar(){
      getSidebar()
      .then((res) => {
        console.log(res);
        this.navLits=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    parentShowNav(val){
      this.showNav = val;
    },
    searchTextCheck(text){
      this.searchText=text;
    },
    search(){
      this.showSearch=false;
      this.$router.push({path:'/all_goods',query:{name:this.searchText}});
    },
    toWapGo(go,data){
      this.showNav=false;
      this.$router.push({
        path:go,
        query:data
      });
    },
    
    
    openNav(){
      
      this.showNav=true;
    },
    
  },
};
</script>

<style lang="scss">
#default_drag_return{
	width: 0.7rem;
	height: 0.7rem;
	font-size: 0.3rem;
  font-weight: bold;
	position: fixed;
	z-index: 1000;
	right: 0.4rem;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color:#fff;
}
.van-nav-bar .van-icon{
    color:#595959;
  }
.van-tabbar-item--active{
  color:#f08200;
}


html,body,#app,.wap-box{
  height: 100%;
}
html,body,.wap-box{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.com-sy-header{
  position: fixed !important;
  top:0;
  left:0;
  z-index: 10;
}
.com-sy-header2{
  height: 110px;
}
.sy-header{
  position: relative;
}
.wap-content{
  overflow: auto;
  height: 100%;
  width: 100%;
}
.van-tabbar{
  flex-shrink: 0;
  position: relative!important;
}
.scroll_box {
  width: 320px;
  height: 200px;
  display: flex;
}

.scroll_warper {
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.scroll_bar {
  height: 4px;
  width: 100%;
  background-color: #eee;
  position: relative;
  right: 0;
  top: 0;
  border-radius: 5px;
}

.scroll_bar_item {
  position: absolute;
  height: 4px;
  width: 10%;
  background-color: rgb(18, 120, 74);;
  border-radius: 5px;
}
.btn-arrow
{
  margin:0;
  padding: 0;
  width: 100px;
  height:40px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: 120px;
  animation:myfirst 1.2s infinite;
  text-align: center;
  .icon{
    color:#fff;
    font-size: 34px;
  }
}
@keyframes myfirst
{
  0%   {opacity:1;bottom: 120px;}
  /*25%  {opacity:0.4; left: 4.6rem;bottom: 2.75rem;}*/
  // 50%  {opacity:0.6; left: 50%;bottom: 2.25rem;}
  60%  {opacity:1; }
  100% {opacity:0.0;bottom: 40px;}
}
@keyframes goToShow
{
  0%   {z-index: -99999;}
  100% {z-index: 99999;}
}
@keyframes goToShow2
{
  0%   {z-index: 99999;}
  100% {z-index: -99999;}
}
@keyframes goToHide
{
  0%   {opacity: 0;z-index: -99999;}
  100% {opacity:.6;z-index: 0;}
}
@keyframes goToLeft
{
  0%   {left:-500px;}
  100% {left:0;}
}
@keyframes goToHide2
{
  0%   {opacity: .6;z-index: 0;}
  100% {opacity: 0;z-index: -99999;}
}
@keyframes goToLeft2
{
  0%   {left:0;}
  100% {left:-500px;}
}

body{
  background: #f7f7f7;
  width: 100%;
  overflow-x: hidden;
}
.sy-header2{
  width: 100%;
  height: 110px;
}
.sy-header{
  animation-duration: .5s!important;
  
  width: 100%;
  height: 110px;
  background: #fff;
  .sy-header-l{
    position: absolute;
    top:50%;
    left: 20px;
    transform: translate(0%, -50%);
    // border:1px solid #ddd;
    border-radius: 30px;
    font-size: 40px;
    width: 80px;
    height: 60px;
    line-height: 60px;
    display: flex;
    .shuxian{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 80%;
      border-left:1px solid #ddd;
    }
  }
  .sy-header-c{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background: url("../assets/images/index/logo.png");
    background-position: top center;
    background-size:100px 200px ;

  }
  .sy-header-r{
    position: absolute;
    top:50%;
    right: 30px;
    transform: translate(0%, -50%);
  }
  .icon{
    line-height: 60px;
    text-align: center;
    flex: 1;
    font-size: 42px;
    color:#838484;
  }
  .icon-search{
    // margin-right: 50px;
  }
  .icon-like-o{
  }
}

.hide{
  display: none;
}
.sy-nav-box2{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background: #fff;
}
.sy-transition-box{
  position: relative;
  z-index: 1;
}


  .sy-nav-bg{
    position: fixed;
    top:110px;
    left:0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    z-index: 1;
  }
  .sy-nav{
    animation:goToLeft2 .3s linear alternate forwards;
    position: fixed;
    top:100px;
    left:0px;
    padding:0 40px;
    width: 400px;
    height: 100vh;
    background:#fff;
    overflow-x: hidden;
    z-index: 99999;
    .sy-nav-top{
      padding-top: 40px;
      padding-bottom: 200px;
      .sy-nav-top-type{
        font-size: 28px;
        color:#313131;
        .van-collapse *{
          border:none !important;
        }
        .van-cell{
          padding: 0;
          margin-top: 60px;
        }
        p, .title{
          padding-left: 20px;
          padding-top: 60px;
          line-height: 60px;
          display: flex;
          .van-icon{
            line-height: 60px;
            font-size: 40px;
            padding-right: 30px;
          }
          
        }
        .title{
          padding-top: 0px;
        }
        ul{
          padding-left:60px;
          font-size: 24px;
          li{
            margin-bottom: 30px;
            color:#313131;
            a{
              color:#313131;
            }
          }
        }
        .left-align{
          margin-top: 10px;
          padding-left: 20px;
        }
      }
    }
    .sy-nav-bottom{
      position: absolute;
      left:0;
      bottom:50px;
      width: 100%;
      background: #eee;
      .sy-nav-bottom-type{
        padding:40px;
        font-size: 24px;
        color:#313131;
        p{
          padding-left: 20px;
          padding-bottom: 30px;
          line-height: 60px;
          display: flex;
          .van-icon{
            line-height: 60px;
            font-size: 38px;
            padding-right: 20px;
          }
          
        }
      }
    }
  }

.sy-nav-bg.dq{
  animation:goToHide .2s linear alternate forwards;
}
.sy-nav.dq{
  animation:goToLeft .3s linear alternate forwards;
}


</style>