<template>
  <div class="all-goods-box" @click="searchHot=false;" @touchstart="category1ButtomId=''">

<van-nav-bar :fixed="true" :placeholder="true" :title="title" left-arrow
  @click-left="toBack"
  @click-right="searchOpen()">
  <template #right>
    <van-icon name="search" size="18" />
  </template>
</van-nav-bar>

    <div class="all-goods-search-box" v-show="searchShow">
      
      
      <div class="sy-header animated ">
        <div  class="search-box" @click.stop="">
          <input type="text" @focus="searchHot=true" @input="getSearchHot" v-model="searchName" placeholder="请输入品名">

          <div class="search-btn" @click="search();title='搜索尊品';">
            <img src="../assets/images/search/search-btn.png" alt="" >
            <span class="queding">确定</span>
          </div>
          

          
          <span class="quxiao" @click="searchShow=false;">关闭</span>
          <div class="sy-nput" @click.stop="">
        
          
          <ul v-show="searchHot && searchName.length>0">
            <li v-for="(a,index) in hotList.slice(0,6)" :key='index'  @click="searchNameCheck(a);search();title='搜索尊品';searchHot=false;">{{a.name}} {{a.specifications}} {{a.style}}</li>
            
          </ul>
        </div>
        </div>
        
      </div>
      
      <div class="sy-search-senior">
        <!-- <div class="history-box">
          <h3 class="senior-title">搜索历史</h3>
          <span>发令尊</span>
          <span>发令尊</span>
          <span>发令尊</span>
          <span>发令尊</span>
        </div> -->
        <div class="senior-box">
          <h3 class="senior-title">高级搜索</h3>
          <div class="senior-select"> 
            <span class="senior-select-title" :class="{'active':category2IdShow==true}" @click="category2IdShow=!category2IdShow;">按系列</span>  
            <div class="senior-select-type" v-show="category2IdShow">
              <span class="check-type" @click="seniorSelect1(a);" v-for="(a,index) in category2List" :key='index' v-show="index<6" :class="{'active':checkCategory2Ids.indexOf(a.id)!=-1}" >{{a.name}}</span>
            </div>
           
          </div>
          <div class="senior-select"> 
            <span class="senior-select-title" :class="{'active':styleIdShow==true}" @click="styleIdShow=!styleIdShow;">按款式</span>  
            <div class="senior-select-type" v-show="styleIdShow">
              <span class="check-type" @click="seniorSelect2(a)" v-for="(a,index) in styleList" :key='index' :class="{'active':checkStyleIds.indexOf(a.id)!=-1}" >{{a.name}}</span>
            </div>
          </div>
          
          <div class="senior-select"> 
            <span class="senior-select-title" :class="{'active':genderShow==true}" @click="genderShow=!genderShow;">按性别</span>  
            <div class="senior-select-type" v-show="genderShow">
              <span class="check-type" @click="seniorSelect3(a)" v-for="(a,index) in genderList" :key='index' :class="{'active':checkGenderIds.indexOf(a.id)!=-1}" >{{a.name}}</span>
            </div>
          </div>
        </div>
        
      </div>
      <!-- <p style="width:100%;text-align:center;" class="look-all">
          <van-button type="default" size="small" style="margin-right:20px;" @click="searchShow=false;selectProductByCategory('senior','','1');">确定</van-button>
          <van-button type="default" size="small" @click="clear()">清空选项</van-button>
        </p> -->

        <div class="look-all">
          <span>
            <img class="s-b-1" src="../assets/images/search/s-b-1.png" alt="" @click="lookAll();">
            <img class="s-b-2" src="../assets/images/search/s-b-2.png" alt="" @click="clear()">
            <img class="s-b-3" src="../assets/images/search/s-b-3.png" alt="" @click="searchShow=false;selectProductByCategory('senior','','1');title='搜索尊品';">
          </span>
        </div>
      
    </div>
    <div v-show="!searchShow">
      <div class="all-goods-type-box" style="text-align:center;" v-show="cpList.length==0">
        敬请期待
      </div>
      <div class="all-goods-type-box" v-show="cpList.length>0">
        <van-grid :gutter="4" :border="false" :column-num="2">
          <van-grid-item v-for="(a,index) in cpList" :key='index' @click="toGo('/detail',{id:a.productCategoryId,specifications:a.specifications,info:a.info})">
            <!-- <van-icon name="like-o" @click.stop="addFavorite(a.id)"  class="icon icon-like-o"/> -->
            <van-image :src="a.cover" alt=""/>
            <h1>{{a.name}} {{a.specifications}}</h1>
            <!-- <h2 v-show="a.price">￥{{a.price}}</h2> -->
          </van-grid-item>
        </van-grid>
        
        <!-- <van-pagination style="margin:20px 0 60px;" v-model="currentPage" @change="changePagination" :page-count="pageCount" v-show="pageCount" mode="simple" /> -->
      </div>

      <div v-show="look" class="all-box" style="bottom:60px;" @touchstart.stop="">
        <div class="all-type" :class="{'active':category1ButtomId==a.id}" v-for="(a,index) in category1ButtomList" :key='index' v-show="index<3"  >
          {{a.name}}
          <div class="all-children" v-show="category1ButtomId==a.id">
            <div class="all-children-type" v-for="(b,index2) in category2ButtomList[a.id]" :key='index2' @click.stop="title=b.name;category2ButtomId=b.id;selectProductByCategory('one','','1');category1ButtomId='';">{{b.name}}</div>
          </div>
        </div>
      </div>
      <div  v-show="look" class="all-box" style="bottom:60px;" @touchstart.stop="">
        <div class="all-type" :class="{'active':category1ButtomId==a.id}" v-for="(a,index) in category1ButtomList" :key='index' v-show="index<3" @click.stop="allLookButton(a.id)" >
          {{a.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {selectProductByCategory,selectStyleCategory,select1Category,select2Category,select3Category,addFavorite} from "../utils/api";
import {mapMutations} from "vuex";
export default {
  components: {
    
  },
  data() {
    return {
      title:'尊品',
      
      look:false,
      activeNames:[],
      currentPage:1,
      active: 1,
      pageCount: '',
      headerShow:true,
      showNav:true,
      navShow:true,
      searchHot:false,
      wrapScroll: {},
      gender:null,
      styleId:'',
      category2Id:'',
      category3Id:'',
      searchShow:false,
      genderShow:false,
      styleIdShow:false,
      category2IdShow:false,
      searchName:'',
      hotList:[
      ],
      cpList:[],
      styleList:[],
      category1Id:'',

      category1ButtomId:'',
      category2ButtomId:'',
      category1ButtomList:[],
      category2ButtomList:{},

      genderList:[
        {'id':0,name:'女性'},
        {'id':1,name:'男性'},
      ],
      category2List:[],
      category3List:[],

      checkStyleIds:[],
      checkCategory2Ids:[],
      checkGenderIds:[],
    }
    
  },
  inject:['appReload'],
  watch: {
    
    '$route' (to, from) {   //监听路由是否变化
      this.appReload();
        // if(to.query.id != from.query.id){  //pid不为空才请求数据
        //     this.category2Id = to.query.id;
        // }
    }
  },
  created(){
    
    
  },
  mounted() {
    this.setBottomNav();
    // setTimeout(()=>{
    //   this.initscroll();
    // },20)
    this.select1Category();
    this.selectStyleCategory();
    this.select2Category();
    // this.select3Category();
    if(this.$route.query.title){
      this.title=this.$route.query.title;
      
    }
    if(this.$route.query.search){
      this.searchShow=true;
    }
    if(this.$route.query.all){
      
    }
    if(this.$route.query.id){
      this.category1Id=this.$route.query.id;
    }
    if(this.$route.query.categoryId){
      this.category2Id=Number(this.$route.query.categoryId);
      this.checkCategory2Ids.push(this.category2Id);
    }
    if(this.$route.query.styleId){
      this.styleId=Number(this.$route.query.styleId);
      this.checkStyleIds.push(this.styleId);
    }
    
    this.selectProductByCategory('senior','','1');
    if(this.title=='全部尊品'){
      this.look=true;
    }
  },
  methods: {
    ...mapMutations(["saveBottomNav"]),
    setBottomNav(){
      this.saveBottomNav('index');
    },
    allLookButton(id){
      console.log(this.category1ButtomId);
      if(this.category1ButtomId==id){
        console.log('一样')
        this.category1ButtomId='';
      }else{
        console.log('不一样')
        this.category1ButtomId=id;
      }
      console.log(this.category1ButtomId);
    },
    searchOpen(){
      this.searchShow=!this.searchShow;
      if(this.checkCategory2Ids.length>0){
          this.category2IdShow=true;
        }else{
          this.category2IdShow=false;
        }
        if(this.checkStyleIds.length>0){
          this.styleIdShow=true;
        }else{
          this.styleIdShow=false;
        }
        if(this.checkGenderIds.length>0){
          this.genderShow=true;
        }else{
          this.genderShow=false;
        }
    },
    seniorSelect1(a){
      if(this.category2Id==a.id){
        this.category2Id='';
      }else{
        this.category2Id=a.id;
      }
      if(this.checkCategory2Ids.indexOf(a.id)==-1){
        this.checkCategory2Ids.push(a.id);
      }else{
        var index = this.checkCategory2Ids.indexOf(a.id);
        this.checkCategory2Ids.splice(index, 1);
      }
      
    },
    seniorSelect2(a){
      if(this.styleId==a.id){
        this.styleId='';
      }else{
        this.styleId=a.id;
      }
      if(this.checkStyleIds.indexOf(a.id)==-1){
        this.checkStyleIds.push(a.id);
      }else{
        var index = this.checkStyleIds.indexOf(a.id);
        this.checkStyleIds.splice(index, 1);
      }
      
    },
    seniorSelect3(a){
      if(this.genderId==a.id){
        this.genderId='';
      }else{
        this.genderId=a.id;
      }
      if(this.checkGenderIds.indexOf(a.id)==-1){
        this.checkGenderIds.push(a.id);
      }else{
        var index = this.checkGenderIds.indexOf(a.id);
        this.checkGenderIds.splice(index, 1);
      }
      
    },
    closeSearch(){
      this.searchShow=false;
    },
    
    
    addFavorite(id){
      addFavorite({
        productId:id
      })
      .then((res) => {
        console.log(res);
        this.$toast.success("收藏成功");
      })
      .catch((err) => {
        console.log(err);
      });
    },
    selectStyleCategory(){
      selectStyleCategory()
      .then((res) => {
        console.log(res);
        this.styleList=res.data;
        
      })
      .catch((err) => {
        console.log(err);
      });
    },
    select1Category(){
      console.log('输入中')
      select1Category()
      .then((res) => {
        console.log(res);
        this.category1ButtomList=res.data;
      })
      select2Category({
        categoryId:1
      })
      .then((res) => {
        console.log(res);
        this.category2ButtomList[1]=res.data;
      })
      select2Category({
        categoryId:2
      })
      .then((res) => {
        console.log(res);
        this.category2ButtomList[2]=res.data;
      })
      select2Category({
        categoryId:3
      })
      .then((res) => {
        console.log(res);
        this.category2ButtomList[3]=res.data;
      })
    },
   
    select2Category(){
      select2Category({
        categoryId:this.category1Id
      })
      .then((res) => {
        console.log(res);
        this.category2List=res.data;
        this.category3Id='';
      })
    },
    select3Category(){
      select3Category({
        categoryId:this.category2Id
      })
      .then((res) => {
        console.log(res);
        this.category3List=res.data;
        this.styleId='';
      });
    },
    getSearchHot(){
      console.log('输入中')
      selectProductByCategory({
        name: this.searchName,
      })
      .then((res) => {
        console.log(res);
        this.hotList=res.pageInfo.records;
      })
    },
    changePagination(val){
      
      this.currentPage=val;
      this.selectProductByCategory('page','',this.currentPage);
    },
    searchNameCheck(arr){
      this.searchName=arr.name;
    },
    lookAll(){
      this.searchShow=false;
      this.category2Id='';
      this.styleId='';
      this.gender='';
      this.searchName='';
      this.currentPage=1;
      this.title='全部尊品';
      this.selectProductByCategory('all','','1');
      
    },
    clear(){
      this.checkStyleIds=[];
      this.checkCategory2Ids=[];
      this.checkGenderIds=[];
    },
    search(){
      this.searchShow=false;
      this.currentPage=1;
      this.selectProductByCategory('',this.searchName,'1');
    },
    goDetail(){
      this.$router.push("/detail");
    },
    initScroll() {
      this.wrapScroll = new BScroll(this.$refs.wrapScroll,{
        scrollX: true,
        scrollY: false,
        scrollbar: {
          fade: false,
          interactive: false,
          // 以下配置项 v2.2.0 才支持
          customElements: [],
          minSize: 8,
          scrollbarTrackClickable: true,
          scrollbarTrackOffsetType: 'step',
          scrollbarTrackOffsetTime: 300,
          // 以下配置项 v2.4.0 才支持
          fadeInTime: 250,
          fadeOutTime: 500
        }
      });
    },
    selectProductByCategory(type,searchName,current){
      let data={};
      if(type=='senior'){
        if(this.checkCategory2Ids.length>0){
          data['categoryId']=this.checkCategory2Ids.toString();
        }
        if(this.checkStyleIds.length>0){
          data['styleId']=this.checkStyleIds.toString();
        }
        if(this.checkGenderIds.length>0){
          data['gender']=this.checkGenderIds.toString();
        }
      }else if(type=='all'){
      }else if(type=='one'){
        this.checkCategory2Ids=[];
        this.checkCategory2Ids.push(this.category2ButtomId);
        data['categoryId']=this.checkCategory2Ids.toString();
      }else if(type=='page'){
        if(this.checkCategory2Ids.length>0){
          data['categoryId']=this.checkCategory2Ids.toString();
        }
        if(this.checkStyleIds.length>0){
          data['styleId']=this.checkStyleIds.toString();
        }
        if(this.checkGenderIds.length>0){
          data['gender']=this.checkGenderIds.toString();
        }
        data['name']=searchName; 
      }else{
        data['name']=searchName; 
      }
      if(current){
        data['current']=current;
      }
      
      if(type=='all'){
        this.look=true;
      }else{
        this.look=false;
      }
      selectProductByCategory(data)
      .then((res) => {
        console.log(res);
        this.cpList=res.pageInfo.records;
        this.currentPage=res.pageInfo.current;
        this.pageCount=res.pageInfo.pages;
      })
    }
  },
};
</script>

<style scoped lang="scss">
.all-goods-box{
  .sy-header-fixed{
    // position: fixed;
    left:0;
    z-index: 1;
  }
  .search-box{
    position: relative;
    margin:20px 0 0 5%;
    width:80%;
    height: 70px;
    background: url('../assets/images/search/search.png') no-repeat;
    background-size:100% 100% ;
    input{
      padding-left: 5%;
      width:70%;
      height: 60px;
      margin-top:5px;
      background: none;
      font-size:30px;
    }
    input::-webkit-input-placeholder { /* WebKit, Blink, Edge */    color:    #ffed3a; }
    input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    #ffed3a; }
    input::-moz-placeholder { /* Mozilla Firefox 19+ */   color:    #ffed3a; }
    input:-ms-input-placeholder { /* Internet Explorer 10-11 */   color:    #ffed3a; }
    .search-btn{
      width: auto;
      height: 100%;
      position: absolute;
      top:0;
      right:0;
      &:hover{
        opacity: 0.7;
      }
      img{
        width: auto;
        height: 100%;
      }
    }
    .queding{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      
      font-size:30px;
      color:#80521a;
    }
    .quxiao{
      position: absolute;
      top:50%;
      transform: translate(0%, -50%);
      right:-80px;
      font-size:30px;
      color:#9c9c9c;
    }
    ul{
        width: 100%;
        position: absolute;
        z-index:99;
        top:80px;
        left:0;
        padding: 0px 5% 40px;
        background: #fff;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
        p{
          padding: 10px 0;
          font-weight: bold;
        }
        span{
          
        }
        li{
          padding: 10px 0;
        }
      }
  }
  .all-goods-search-box{
    // padding-top: 110px;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: #fff url('../assets/images/common/box.jpg') no-repeat center bottom;
    background-size:100% auto ;
    z-index: 1;
    .sy-header-c-s{
      font-size: 30px;
      color: #1e1617;
      text-align: center;
      line-height: 110px;
    }
    .look-all{
      width: 100%;
      padding:60px 0 200px;
      
      text-align: center;
      span{
        display: inline-block;
        position: relative;
      }
      img{
        width: auto;
        height: 60px;
        &:hover{
          opacity: .7;
        }
      }
      .s-b-2, .s-b-3{
        position: absolute;
        top:0;
        left:0;
      }
      .look-all-btn{
        width: 44%;
        margin:0 auto;
        position: relative;
        img{
          width: 100%;
        }
        span{
          width: 100%;
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          
          font-size:30px;
        }
      }

    }
    h1{
      padding:100px 0 100px;
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      letter-spacing:8px;
    }
    .sy-search-bottom{
      margin-bottom: 40px;
      display: flex;
      .sy-search-select{
        flex:1;
        border-bottom: 1px solid #ccc;
      }
      select{
        width: 100%;
        height: 80px;
        line-height: 80px;
        background: none;
        
      }
      .sy-search-input{
        flex:1;
        width: 80%;
        line-height: 50px;
        font-size: 28px;
        position: relative;
        border-bottom: 1px solid #e9e9e9;
        // padding: 30px;
        
      }
      // input{
      //   width: 100%;
      //   height: 80px;
      //   line-height: 80px;
      //   font-size: 24px;
      //   background: #efefef;
      //   // border-bottom: 1px solid #ccc;
      // }
      ul{
        width: 100%;
        position: absolute;
        top:110px;
        left:0;
        padding: 0 80px;
        background: #fff;
        span{
          font-weight: bold;
        }
        li{
        }
      }
      .sy-search-bottom-submit{
        margin-left: 20px;
        width: 80px;
        height: 80px;
        background: #fd9a0d;
        border-radius: 40px;
      }

    }
    .sy-search-senior{
      margin-top: 30px;
      padding: 0 10%;
      margin-bottom: 50px;
      .senior-title{
        font-size: 32px;
        color:#1e1617;
        margin-bottom: 30px;
      }
      .history-box{
        margin-bottom: 40px;
        span{
          padding:6px 16px 8px;
          margin:0 10px 10px 0;
          display: inline-block;
          background: #f2f2f2;
          border-radius: 50px;
        }
      }
      .senior-box{
        
        color:#4c4c4c;
        .senior-select{
          padding-bottom: 10px;
          margin-bottom: 40px;
          border-bottom:1px solid #4d4d4d;
          .senior-select-title{
            display: inline-block;
            position: relative;
            margin-bottom: 30px;
            &::before{
              content: '';
              position: absolute;
              top:0px;
              right:-40px;
              width: 26px;
              height: 26px;
              background: url('../assets/images/common/down.png');
              background-size:100% 100% ;
            }
            &.active::before{
              
              background: url('../assets/images/common/down2.png');
              background-size:100% 100% ;
            }
          }
          .senior-select-type{
            
            .check-type{
              padding:6px 16px 8px;
              margin:0 20px 20px 0;
              display: inline-block;
              border:1px solid #4d4d4d;
              border-radius: 50px;
            }
            .active{
              color:#f08200;
              border-color: #f08200;
            }
          }
        }
        
      }
    }
  }
  .icon-like-o{
    position: absolute;
    top:20px;
    left:20px;
    font-size: 40px;
    z-index: 10;
  }
  .detail-top-box{
    background: rgba(0, 0, 0, 0.9) url('../assets/images/detail/bg.jpg') center center;
    background-size: cover;
    height: 800px;
  }
  .all-goods-type-box{
    background: #f7f7f7;
    padding:2vh 10px 5vh;
  }
  .all-box{
    position: fixed;
    left:5%;
    
    width: 90%;
    height: 70px;
    line-height: 70px;
    background: url('../assets/images/index/all1.png');
    background-size:100% 100% ;
    display: flex;
    .all-type{
      position: relative;
      flex:1;
      text-align: center;
      color:#4c4c4c;
      &.active{
        color:#f08200;
        background: url('../assets/images/index/all2.png');
        background-size:100% 100% ;
      }
      .all-children{
        color:#4c4c4c;
        position: absolute;
        left:3%;
        bottom:60px;
        width: 94%;
        height: auto;
        background: url('../assets/images/index/all3_02.png');
        background-size:100% 100% ;
        &::before{
          content: '';
          position: absolute;
          left: 3%;
          top:-60px;
          width: 94%;
          height: 60px;
          background: url('../assets/images/index/all3_01.png');
          background-size:100% 100% ;
        }
        .all-children-type{
          margin:0 auto 0px;
          width: 80%;
          border-bottom:1px solid #e6e6e6 ;
          &:last-child{
            border:none;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  
}
</style>
