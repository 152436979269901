<template>
  <div>

    <div class="com-sy-header2"></div>
    <div class="com-sy-header sy-header animated" >
      <div class="sy-header-l">
        <!-- <van-icon  name="arrow-left" class="icon " @click="toBack"/>
        <div class="shuxian"></div> -->
        <van-icon @click.stop='showNav=!showNav;' name="wap-nav" class="icon icon-wap-nav"/>
      </div>
      <div class="sy-header-c">
      </div>
      <div class="sy-header-r">
        <van-icon name="search"  @click="toGo('/all_goods',{search:'1',title:'搜索尊品'})" class="icon icon-search"/>
        <!-- <van-icon name="contact"  @click="toWapGo('/user')" class="icon icon-like-o"/> -->
      </div>
      
      <div class="sy-nav-bg" v-show="showNav" @click.stop="showNav=false;"></div>
      <div class="sy-nav" :class="{'dq':showNav}">
          
          <div class="sy-nav-top">
            <div class="sy-nav-top-type">
              <p v-show="!$cookies.get('Authorization')" @click="toWapGo('/login')">
                <span>登录</span>
              </p>  
              <p v-show="$cookies.get('Authorization')" @click="onExit()">
                <span>退出登录</span>
              </p>
              <p @click="toWapGo('/mizun')">
                <span>首页</span>
              </p>

               
              <van-collapse v-model="activeNames" :border="false">
                <van-collapse-item :name="index" :border="false" v-for="(a,index) in navLits" :key="index" v-show="index!='皇宫御制'">
                  <template #title>
                    <div class="title">
                    <span>{{index}}</span></div>
                  </template>
                  <ul>
                    <li v-for="(b,index) in a" :key="index" @click="toWapGo('/all_goods',{categoryId:b.id,title:b.name})">{{b.name}}</li>
                  </ul>
                </van-collapse-item>
              </van-collapse>
              
              
              <p>
                <!-- <van-icon name="user-circle-o" /> -->
                <span>联系我们</span>
              </p>
              <ul class="left-align">
                <li>密尊 | 中国广州总部</li>
                <li><a href="tel:020-87593616">(020) 8759 3616 [品牌总线]</a></li>
                <li><a href="tel:020-87595616">(020) 8759 5616 [客服专线]</a></li>
              </ul>
            </div>
           
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import {getSidebar} from "../utils/api";
export default {
  props: {
    
  },
  data(){
    return{
      activeNames:[],
      navLits:[],
      showNav:false,
      title:'',
    }
  },
  mounted() {
    this.getSidebar();
    // console.log(this.$cookies.get('Authorization'))
  },
  methods: {
    //退出登录
    onExit(){
      this.$cookies.remove("Authorization");
      this.$router.push("/login");
    },
    toWapGo(go,data){
      this.showNav=false;
      this.$router.push({
        path:go,
        query:data
      });
    },
    getSidebar(){
      getSidebar()
      .then((res) => {
        console.log(res);
        this.navLits=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
